// $(".home-tab").on("click", function() {
//     const tab = $(".nav").find(".active-login-tab ");
//     tab.removeClass("active-login-tab");

//     console.log(tab)
//     tab.addClass("home-login-tabs");

//     const parent = $(this).parent();
//     // parent.fadeOut()
//     console.log(parent)
//     parent.removeClass("home-login-tabs")
//     parent.addClass("active-login-tab");
// });

// $(".home-tab").on("click", function() {
//     var temp = $(".nav").find(".active-login-tab ");
//     temp.removeClass("active-login-tab");
//     console.log(temp)
//     temp.addClass("home-login-tabs");

//     var temp = $(this).parent();
//     console.log(temp)
//     temp.removeClass("home-login-tabs")
//     temp.addClass("active-login-tab");

// });

// Todo, doesnt work yet
$(window).on("scroll", () => {
    if ($(document).scrollTop() > 700) {
        $("nav").addClass("d-none");
        $("nav").removeClass("transparent");
    } else {
        $("nav").addClass("transparent");
        $("nav").removeClass("bg-faded");
    }
});
